import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './propertyListing.css';
import PropertyListingItem from './propertyListingItem';
import AIRARECT from '../../Assets/images/Aira_Logo_purple.png';
import EMPTYLIST from '../../Assets/images/empty-properties.svg';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TypingEffect from '../../components/typingEffect';
import SearchIcon from '@mui/icons-material/Search';
import { GOOGLE_MAPS_API_KEY } from '../../constants/app-constants';
import { Loader } from '@googlemaps/js-api-loader';
import AdvanceFiltersModal from '../../components/advance-filter-modal';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DelayedRender from '../../components/DelayedRender';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../../Utils/translations';

const AgentSkeleton = () => {
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 2,
        marginBottom: 2,
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* Agent Image Skeleton */}
        <Skeleton variant="circular" width={60} height={60} />
        <Box sx={{ flex: 1 }}>
          {/* Agent Name and Verification Badge */}
          <Skeleton variant="text" width="30%" height={20} />
          {/* Agent Description */}
          <Skeleton
            variant="text"
            width="60%"
            height={15}
            sx={{ marginTop: 1 }}
          />
          <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
            <Skeleton variant="rectangular" width={50} height={25} />
            <Skeleton variant="rectangular" width={50} height={25} />
          </Stack>
        </Box>
        {/* Active Listings */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="text" width={80} height={20} />
        </Box>
      </Stack>
      {/* Areas of Operation and Specialization */}
      <Box sx={{ marginTop: 2 }}>
        <Skeleton variant="text" width="40%" height={15} />
        <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
          <Skeleton variant="rectangular" width={80} height={25} />
          <Skeleton variant="rectangular" width={80} height={25} />
        </Stack>
      </Box>
    </Box>
  );
};

const PropertyListing = (props) => {
  const {
    loader,
    matchCount,
    matchStatus,
    propertyList,
    propertyType,
    selectedCity,
    houseType,
    bedrooms,
    setBedRooms,
    dropDownValues,
    getStateValues,
    location,
    setLocation,
    handlePropertyDisplay,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const lgBreakpointMatches = useMediaQuery(theme.breakpoints.up('lg'));

  const [extendFilter, setExtendFilter] = useState(false);
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const inputRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userName = useSelector((state) => state.userDetails.userName);
  const autocompleteServiceRef = useRef(null);
  const debounceRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    });
  }, []);

  useEffect(() => {
    if (
      houseType?.some((type) =>
        ['Warehouse', 'Showroom', 'Office', 'Shop'].includes(type)
      )
    ) {
      setBedRooms([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [houseType]);

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPredictions = (input) => {
    if (!input || !autocompleteServiceRef.current) return;

    setLoading(true);

    autocompleteServiceRef.current.getPlacePredictions(
      {
        input,
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'in' },
        locationBias: {
          center: { lat: 12.9716, lng: 77.608476 }, // Bangalore center coordinates
          radius: 20000, // 20 km 
        },
      },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          const filteredPredictions = predictions.filter((place) =>
            place.description.includes("Bangalore") || place.description.includes("Bengaluru")
          );
          setOptions(filteredPredictions);
        } else {
          setOptions([]);
        }
        setLoading(false);
      }
    );
  };

  const handleInputChange = (e, val) => {
    setLocation(val);
    debouncedFetchPredictions(val);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchPredictions = useCallback(
    debounce(fetchPredictions, 300),
    []
  );

  return (
    <div className="property-listing-container">
      {loader ? (
        <Box>
          <Box sx={{ width: { lg: '1200px', xs: '100%' }, padding: 4 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              <Skeleton sx={{ width: { lg: '50%', xs: '100%' } }} />
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              <Skeleton sx={{ width: { lg: '70%', xs: '100%' } }} />
            </Typography>
            <AgentSkeleton />
            <AgentSkeleton />
            <AgentSkeleton />
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={40}
                sx={{ borderRadius: 20 }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: '80vh',
            width: { lg: '75%', xs: '90%' },
            margin: '25px 0',
          }}
        >
          <Typography className="property-listing-title">
            <TypingEffect text={`Hello, ${userName || ''}`} />
          </Typography>
          <Box
            sx={{ position: 'relative' }}
            className="property-listing-banner"
          >
            <Box
              sx={{
                background:
                  'linear-gradient(45deg,rgba(219, 182, 247, 0.1) 0%,rgba(248, 234, 244, 1)  40%, rgba(248, 234, 244, 0.1) 60%, rgba(234, 202, 255, 1) 80%,  rgba(96, 13, 180, 1) 125%)',
                borderRadius: '16px',
                width: '100%',
                justifyContent: 'center',
                paddingLeft: { lg: 6, xs: '12px' },
                paddingTop: 2,
                paddingBottom: 2,
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <img alt="aira" src={AIRARECT} width={22} height={24} />
                <Typography
                  sx={{ color: '#590DB4', fontWeight: 700, marginLeft: 1 }}
                >
                  AIRA
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: '#474747',
                  fontWeight: '400',
                  fontSize: { lg: '16px', xs: '14px' },
                  width: { lg: '75%', xs: '100%' },
                  zIndex: 2,
                  paddingTop: { lg: 1, xs: '4px' },
                }}
              >
                <TypingEffect
                  text={
                    matchStatus
                      ? matchCount === 1
                        ? t(TRANSLATIONS.PARAGRAPHS.FOUND_A_PROP_MATCH)
                        : `<span>${t(
                            TRANSLATIONS.PARAGRAPHS.FOUND_SEVERAL_PROP_MATCH
                          )} <b>${t(TRANSLATIONS.TITLES.TOP)} ${matchCount} ${t(
                            TRANSLATIONS.TITLES.AGENTS
                          )} </b>${t(
                            TRANSLATIONS.PARAGRAPHS.WHO_HAVE_LISTED
                          )}</span>`
                      : t(TRANSLATIONS.PARAGRAPHS.COULD_NOT_FIND_ANY_PROP)
                  }
                />
              </Typography>
            </Box>
          </Box>

          {!extendFilter && lgBreakpointMatches ? (
            <Box
              sx={{
                background: 'rgba(255, 255, 255, 1)',
                padding: '10px 15px',
                paddingRight: '7px',
                borderRadius: '75.59px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '35%',
                cursor: 'pointer',
                margin: '10px auto',
                boxShadow: '26px 16px 76px #ababab, -26px -26px 76px #ffffff',
                position: 'fixed',
                bottom: '30px',
                left: '30%',
                zIndex: 500,
                border: '1px solid rgba(227, 227, 227, 1)',
              }}
              onClick={() => setExtendFilter(true)}
            >
              <Typography
                sx={{
                  color: '#7D7D7D',
                  fontSize: '14px',
                  fontWeight: 600,
                  padding: '0 10px',
                }}
              >
                {selectedCity.length
                  ? `${selectedCity[0]} ${
                      selectedCity?.length > 1
                        ? `+ ${selectedCity?.length - 1}`
                        : ''
                    } `
                  : +' ' + t(TRANSLATIONS.TITLES.CITY)}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  color: '#7D7D7D',
                  fontSize: '14px',
                  fontWeight: 600,
                  padding: '0 10px',
                }}
              >
                {propertyType.length
                  ? `${propertyType[0]} ${
                      propertyType?.length > 1
                        ? `+ ${propertyType?.length - 1}`
                        : ''
                    } `
                  : t(TRANSLATIONS.TITLES.BUY_PURCHASE)}
              </Typography>

              <Divider orientation="vertical" flexItem />
              <Typography
                sx={{
                  color: '#7D7D7D',
                  fontSize: '14px',
                  fontWeight: 600,
                  padding: '0 10px',
                }}
              >
                {houseType.length
                  ? `${houseType[0]} ${
                      houseType?.length > 1 ? `+ ${houseType?.length - 1}` : ' '
                    } `
                  : t(TRANSLATIONS.TITLES.PROPERTY_TYPE)}
              </Typography>
              <Box
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '50%',
                  background: '#590DB4',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                color="primary"
                aria-label="add"
              >
                <SearchIcon sx={{ width: '18px', color: '#ffffff' }} />
              </Box>
            </Box>
          ) : (
            <Modal
              open={extendFilter}
              onClose={() => setExtendFilter(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'none',
                '& .MuiModal-backdrop': {
                  background: 'none',
                },
              }}
            >
              <Box
                className="animated-div zoom-in"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',
                  bottom: '30px',
                  left: '0',
                  zIndex: 500,
                  outline: 'none',
                }}
              >
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right', // Align to center horizontally
                  }}
                  transformOrigin={{
                    vertical: 'bottom', // Position popover's bottom to anchor's top
                    horizontal: 'center',
                  }}
                  sx={{
                    '.MuiBox-root': {
                      border: 'none',
                      borderRadius: '10px',
                      boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
                      cursor: 'pointer',
                      alignItems: 'center',
                      minWidth: '200px !Important',
                      maxWidth: '200px !Important',
                      width: '200px !Important',
                    },
                    '.MuiPopover-paper': {
                      minWidth: '200px !Important',
                      maxWidth: '200px !Important',
                      width: '200px !Important',
                    },
                  }}
                >
                  {activeFilter &&
                    activeFilter !== 'locality' &&
                    dropDownValues &&
                    dropDownValues[activeFilter].map((item) => {
                      return (
                        <Box
                          sx={{
                            borderBottom: '1px solid #D2D2D2 ',
                            p: 1.5,
                            background: getStateValues(
                              activeFilter
                            ).state.includes(item.value)
                              ? '#F5EDFF'
                              : 'background.paper',
                            cursor: 'pointer',
                            fontSize: '14px',
                            width: '100%',
                            borderRadius: '8px',
                          }}
                          onClick={() => {
                            if (
                              getStateValues(activeFilter).state.includes(
                                item.value
                              )
                            ) {
                              getStateValues(activeFilter).setState(
                                getStateValues(activeFilter).state.filter(
                                  (i) => {
                                    return i !== item.value;
                                  }
                                )
                              );
                            } else {
                              getStateValues(activeFilter).setState([
                                ...getStateValues(activeFilter).state,
                                item.value,
                              ]);
                            }
                          }}
                        >
                          {item.value}
                        </Box>
                      );
                    })}
                </Popover>

                <Box
                  sx={{
                    background: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100px',
                    margin: '10px auto',
                    boxShadow:
                      '26px 26px 76px #ababab, -26px -26px 76px #ffffff',

                    border: '1px solid rgba(227, 227, 227, 1)',
                  }}
                  className={activeFilter ? 'home-inactive-filter' : ''}
                >
                  <Box
                    sx={{
                      width: '130px',
                      margin: '0 10px',
                      marginLeft: '0',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      padding: '20px 10px',
                      paddingLeft: '22px',
                    }}
                    onClick={(e) => {}}
                    className={
                      activeFilter === 'cities' ? 'home-active-filter' : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#7D7D7D',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.CITY)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {selectedCity.length
                        ? `${selectedCity[0]} ${
                            selectedCity?.length > 1
                              ? `+ ${selectedCity?.length - 1}`
                              : ''
                          } `
                        : t(TRANSLATIONS.TITLES.CITY)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '170px',
                      margin: '0 10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      cursor: 'pointer',
                      padding: '20px 10px',
                      paddingLeft: '15px',
                    }}
                    onClick={(e) => {
                      setActiveFilter('locality');
                      inputRef.current.focus();
                    }}
                    className={
                      activeFilter === 'locality' ? 'home-active-filter' : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#7D7D7D',
                        marginBottom: '2px',
                        paddingLeft: '13px',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.LOCALITY)}
                    </Typography>
                    <Autocomplete
                      size="small"
                      sx={{
                        width: '150px',
                        backgroundColor: 'transparent',
                        borderRadius: '8px',
                        '.MuiOutlinedInput-root': {
                          border: 'none',
                          borderRadius: '8px',
                          fontSize: '14px',
                          height: '20px',
                          width: '300px',
                          padding: '0',
                          boxShadow: 'none',
                          '& fieldset': {
                            border: 'none',
                          },
                        },
                        '.MuiInputBase-input': {
                          padding: '0 8px',
                          margin: 0,
                          textAlign: 'left',
                        },
                      }}
                      freeSolo
                      ref={inputRef}
                      options={options}
                      getOptionLabel={(option) => option.description || ''}
                      filterOptions={(x) => x}
                      loading={loading}
                      inputValue={location}
                      onInputChange={handleInputChange}
                      onChange={(e, val) => {
                        if (val) {
                          if (val?.description) {
                            setLocation(val?.description);
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          ref={inputRef}
                          size="small"
                          {...params}
                          placeholder="Search"
                          variant="outlined"
                          sx={{
                            fontSize: '14px',
                            height: '20px',
                            padding: '0',
                            overflow: 'hidden',
                            '.MuiOutlinedInput-input': {
                              padding: '0 8px',
                            },
                            '.MuiOutlinedInput-root': {
                              border: 'none',
                              boxShadow: 'none',
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none',
                              },
                            },
                          }}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={10} />
                                ) : null}
                                <span onClick={() => setLocation('')}>
                                  {params.InputProps.endAdornment}
                                </span>
                              </>
                            ),
                            startAdornment: null,
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: '150px',
                      padding: '20px 10px',

                      margin: '0 10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      cursor: 'pointer',
                      paddingLeft: '15px',
                    }}
                    onClick={(e) => {
                      setActiveFilter('new_list_type');
                      handleClick(e);
                    }}
                    className={
                      activeFilter === 'new_list_type'
                        ? 'home-active-filter'
                        : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#7D7D7D',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.LOOKING_FOR)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {propertyType.length
                        ? `${propertyType[0]} ${
                            propertyType?.length > 1
                              ? `+ ${propertyType?.length - 1}`
                              : ''
                          } `
                        : t(TRANSLATIONS.TITLES.SELECT)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '180px',
                      padding: '20px 10px',
                      margin: '0 10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      cursor: 'pointer',
                      paddingLeft: '15px',
                    }}
                    onClick={(e) => {
                      setActiveFilter('property_types');
                      handleClick(e);
                    }}
                    className={
                      activeFilter === 'property_types'
                        ? 'home-active-filter'
                        : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#7D7D7D',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.PROPERTY_TYPE)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {houseType.length
                        ? `${houseType[0]} ${
                            houseType?.length > 1
                              ? `+ ${houseType?.length - 1}`
                              : ''
                          } `
                        : t(TRANSLATIONS.TITLES.SELECT)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '135px',
                      padding: '20px 10px',

                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      cursor: !houseType?.some((type) =>
                        ['Warehouse', 'Showroom', 'Office', 'Shop'].includes(
                          type
                        )
                      )
                        ? 'pointer'
                        : 'not-allowed',
                      paddingLeft: '15px',
                    }}
                    onClick={(e) => {
                      if (
                        !houseType?.some((type) =>
                          ['Warehouse', 'Showroom', 'Office', 'Shop'].includes(
                            type
                          )
                        )
                      ) {
                        setActiveFilter('bedrooms');
                        handleClick(e);
                      }
                    }}
                    className={
                      activeFilter === 'bedrooms' ? 'home-active-filter' : ''
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#7D7D7D',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.BEDROOM)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {bedrooms.length
                        ? `${bedrooms[0]} ${
                            bedrooms?.length > 1
                              ? `+ ${bedrooms?.length - 1}`
                              : ''
                          } `
                        : t(TRANSLATIONS.TITLES.SELECT)}
                    </Typography>
                  </Box>

                  <span
                    style={{
                      margin: '0 10px',
                      border: '1px solid #600DB4',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      width: '35px',
                      height: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => setOpenAdvanceFilter(true)}
                  >
                    <TuneOutlinedIcon color="primary" fontSize="small" />
                  </span>

                  <Button
                    onClick={() => {
                      handlePropertyDisplay();
                      setActiveFilter(null);
                      setExtendFilter(false);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      color: '#ffffff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '100px',
                      fontSize: '14px',
                      padding: '7px 12px',
                      margin: '0 8px',
                      marginRight: '25px',
                      cursor: 'pointer',
                      textTransform: 'none',
                    }}
                    disabled={
                      selectedCity.length < 1 || propertyType.length < 1
                    }
                  >
                    <SearchIcon
                      sx={{
                        width: '18px',
                        marginRight: '6px',
                        color: '#FFFFFF',
                      }}
                    />
                    {t(TRANSLATIONS.TITLES.SEARCH)}
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}

          <DelayedRender waitBeforeShow="3000">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {propertyList?.length ? (
                propertyList.map((item) => (
                  <PropertyListingItem
                    key={item.id}
                    item={item}
                    city={selectedCity}
                    locality={location}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    minHeight: '40vh',
                    marginY: 3,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <img alt="no properties" src={EMPTYLIST} />
                  <Typography
                    sx={{
                      color: '#A49D9D',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginY: 2,
                      textAlign: 'center',
                    }}
                  >
                    {t(TRANSLATIONS.PARAGRAPHS.NO_PERFECT_MATCH_YET)}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#A49D9D',
                      fontSize: '16px',
                      fontWeight: 400,
                      width: { lg: '35%', xs: '100%' },
                      textAlign: 'center',
                    }}
                  >
                    {t(TRANSLATIONS.PARAGRAPHS.BUT_WE_HAVE_ALERTED_OUR_NETWORK)}
                  </Typography>
                </Box>
              )}
            </Box>
          </DelayedRender>

          <DelayedRender waitBeforeShow="3000">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                margin: '22px 0',
              }}
            >
              <img width={22} height={24} src={AIRARECT} alt="aira" />
              <Box
                sx={{
                  marginLeft: 1,
                  marginBottom: '120px',
                }}
              >
                <Typography
                  sx={{ color: '#474747', fontWeight: 700, fontSize: '16px' }}
                >
                  {t(TRANSLATIONS.PARAGRAPHS.LOOKING_FOR_SOMETHING_SPECIFIC)}
                </Typography>
                <Typography
                  sx={{ margin: '10px 0', fontSize: '16px', fontWeight: 400 }}
                >
                  {t(TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_PREFERRED_LOCALITY)}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setExtendFilter(true);
                  }}
                >
                  {t(TRANSLATIONS.TITLES.SHARE_NOW)}
                </Button>
              </Box>
            </Box>
          </DelayedRender>

          {/* -------------- Filter Module -------------------- */}
        </Box>
      )}

      <AdvanceFiltersModal
        {...props}
        handleSubmit={handlePropertyDisplay}
        dropDownValues={{ ...dropDownValues }}
        open={openAdvanceFilter}
        setOpen={setOpenAdvanceFilter}
      />
    </div>
  );
};

export default PropertyListing;
