import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import './propertyDetail.css';

import ViewProfile from '../Pages/Broker/ViewProfile';
import { IconX } from '@tabler/icons-react';

export default function ProfileDetail(props) {
  const { toggleDrawer, open, customer_id } = props;

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box
        id="property-detail"
        sx={{ width: 800 }}
        role="presentation"
        className="property-detail-container"
      >
        <span className="property-detail-close" onClick={toggleDrawer(false)}>
          <IconX className="property-detail-close-icon" />
        </span>

        <ViewProfile customerId={customer_id} sidePreview={true} />
      </Box>
    </Drawer>
  );
}
